<template>
  <v-snackbar :color="currentMessage.color" :value="showSnackbar" bottom right>{{ currentMessage.text }}</v-snackbar>
</template>

<script>
import eventBus from '@/eventBus.js';

export default {
  name: 'TheSnackbarQueueHandler',
  data: () => ({
    messages: [],
    hideTimeout: 0,
    showSnackbar: false,
  }),
  computed: {
    currentMessage() {
      if (!this.messages.length) return false;
      return this.messages[0];
    },
  },
  created() {
    eventBus.$on('snackbar', (snackbar) => {
      this.messages.push(snackbar);
      this.handleNextMessage();
    });
  },
  destroyed() {
    clearTimeout(this.hideTimeout);
  },
  methods: {
    handleNextMessage() {
      if (this.showSnackbar) return; //already showing an message
      if (!this.messages.length) return; // no more messages to show

      this.showSnackbar = true;

      this.hideTimeout = setTimeout(() => {
        this.showSnackbar = false;
        setTimeout(() => {
          this.messages.splice(0, 1);
          this.handleNextMessage();
        }, 500);
      }, 5000);

    },
  },
};
</script>
