<template>
  <v-menu
      bottom
      left
      offset-y
      transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="mr-6" fab icon>
        <v-avatar class="accent" size="48" title>
          <v-icon>fa-user</v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-list>
      <v-list-item :href="portalUrl">
        <v-list-item-title>{{ $t('global.portal') }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-title>{{ $t('authorisation.actions.logout') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppBarMenu',
  computed: {
    portalUrl() {
      return process.env.VUE_APP_PORTAL_URL;
    },
  },
  methods: {
    ...mapActions({
      logout: 'authorisation/logout',
    }),
  },
};
</script>
